import React, { useState } from "react";
import {
  Box,
  Card,
  Container,
  Typography
} from "@mui/material";
import CheckoutComponent from "components/checkout/CheckoutComponent";

const Checkout = () => {

  const [hasError, setHasError] = useState({state: false, message: ""});

  return (
    <Box mt={10}>
      <Card sx={{borderRadius: '10px'}}>
        {
          (!hasError.state)
          ?<CheckoutComponent data={{hasError, setHasError}}/>
          :<>
          <Typography>{hasError.message}</Typography>
          </>
        }
      </Card>
    </Box>
  )
}

export default Checkout;