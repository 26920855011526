import React from "react";
import {
  Box,
  Typography
} from '@mui/material';
import { Cancel } from '@mui/icons-material';

const Error = (props) => {

  return(
    <Box mt={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Cancel sx={{color: "rgb(34 197 94)", display: "block", width: "80px", height: "80px"}}/>
      <Typography variant="h4" color="white">Pago fallido</Typography>
      <Typography variant="h6" color="white">Ha ocuirrido un error al procesar su pago.</Typography>
    </Box>
  )
}

export default Error;