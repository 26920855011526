import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
 } from "@mui/material";
 import axios from "axios";
 import axiosApp from "http/Http";

const Login = () => {
  const navigate = useNavigate();
  const [correo, setCorreo] = useState('');
  const [pass, setPass] = useState('');

  const { setUser } = useContext(AuthContext);
  const handleSubmit = async (event, correo, pass) => {
    event.preventDefault();

    const token = b64EncodeUnicode(correo + ':' + pass);
    const headers = {
      'Authorization': 'Basic ' + token,
      'Access-Control-Allow-Origin': '*',
      'User-Device': 'AppBastos-Web'
    }

    const promise = axios.get(process.env.REACT_APP_API_BASE_URL + '/login', {
      headers: headers
    });

    toast.promise(promise,{
      loading: 'Iniciando sesión...',
      success: (data)=>{
        const session = data.data.data;
        window.localStorage.setItem('Session', JSON.stringify(session));
        getUser(session.id);
        return 'Ha iniciado sesión!'
      },
      error: (error) => {
        try{
          switch(error.response.status){
            case 400:
              return "Correo o contraseña incorrectos.";
            case 401:
              return error.response.data.message;
            case 403:
              return "No se ha verificado la dirección de correo.";
            default:
              return "Ha ocurrido un error inesperado, intente nuevamente."
          }
        }
        catch(ex){
          return "Ha ocurrido un error inesperado, intente nuevamente."
        }
      },
    })
  };

  const getUser = (id) => {
    const promise = axiosApp.get(process.env.REACT_APP_API_BASE_URL + '/api/usuarios/getusuario?id=' + id);

    toast.promise(promise,{
      loading: 'Cargando usuario...',
      success: (data)=>{
        setUser({...data.data.data});
        navigate('/');
        return 'Se han obtenido el usuario!'
      },
      error: (error) => {
        console.error(error);
        return "Ha ocurrido un error al obtener el usuario!"
      },
    })
  };

  const b64EncodeUnicode = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  }

  return (
    <Box my={3}>
      <Card sx={{maxWidth: '750px', minHeight: '300px', m: 'auto'}}>
        <CardMedia
          component="img"
          height="200"
          image="/images/logo-app-2.png"
          sx={{objectFit: 'contain'}}
          alt="AppBastos logo"
        />
        <CardContent>
          <form onSubmit={(event)=>{handleSubmit(event, correo, pass)}}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item lg={12}>
                <Typography variant="h4" align="center">Iniciar sesión</Typography>
              </Grid>
              <Grid item lg={12}>
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo"
                  variant="standard"
                  type="text"
                  onChange={(e)=>setCorreo(e.target.value)}
                  defaultValue={correo}
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Contraseña"
                  variant="standard"
                  type="password"
                  onChange={(e)=>setPass(e.target.value)}
                  defaultValue={pass}
                />
              </Grid>
              <Grid item lg={12}>
                <Box display="flex" justifyContent="center">
                  <Button  sx={{bgcolor: '#40aa54', ":hover": {bgcolor: '#4bd966'}}} type="submit" variant="contained">Iniciar sesión</Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Login;