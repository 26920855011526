import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import {
  Avatar,
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Box,
  Typography,
} from '@mui/material';

const settings = [{id: 1, value: 'Cerrar sesión'}];

function ResponsiveAppBar() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const LogOut = () => {
    window.localStorage.removeItem('Session');
    window.location.href = '/login';
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' sx={{bgcolor: '#40aa54'}}>
        <Container maxWidth='lg' sx={{p: '0'}}>
          <Toolbar>
            <IconButton onClick={()=>{navigate('/profile')}}>
              <Avatar alt={user?.nombre + ' ' + user?.apellidoPaterno} src={user?.imagenPerfil}/>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {user?.nombre + ' ' + user?.apellidoPaterno}
            </Typography>
            <Button sx={{color: 'white'}} onClick={LogOut}>Cerrar sesión</Button>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
export default ResponsiveAppBar;