import { useState, useEffect, useContext, createContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosApp from "http/Http";

export const AuthContextProvider = (props) => {
  const { children } = props;

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate()
  
  const [user, setUser] = useState({});
  const [session, setSession] = useState(JSON.parse(window.localStorage.getItem('Session')));
  const path = window.location.pathname;
  const allowedPaths = ["login"]

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    let isAllowed = false;
    allowedPaths.forEach(item => {
      if(path.includes(item)){
        isAllowed = true;
      }
    })
    if(!isAllowed){
      async function GetUsuario(){
        if(searchParams.get('token')){
          const session = { firebaseToken: searchParams.get('token') };
          window.localStorage.setItem('Session', JSON.stringify(session));
          const response = await axiosApp.get(BASE_URL + "/Login/GetUsuario");
          if(response.status == 200){
            setUser({...response.data.data});
          }
          else{
            navigate('/login');
          }
        }
        else if(session){
          axiosApp.get(BASE_URL + "/Login/GetUsuario")
          .then(response => {
            if(response.status == 200){
              setUser({...response.data.data});
            }
          })
          .catch(error => {
            window.localStorage.removeItem('Session');
            navigate('/login');
          })
        }
        else{
          navigate('/login');
        }
      }
  
      GetUsuario();
    }
  },[]);

  return(
    <AuthContext.Provider value={{user, setUser}}>
      {children}
    </AuthContext.Provider>
  )
}