import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import { GetCreditos, GetPedido, GetSolicitud, RealizarPago } from "http/Checkout";

const CheckoutComponent = (props) => {

  const { hasError, setHasError } = props.data;
  const [isLoading, setIsLoading] = useState(true);
  const [useCredits, setUseCredits] = useState(false);
  const [pedido, setPedido] = useState({});
  const [creditos, setCreditos] = useState(0);
  const [solicitudes, setSolicitudes] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    setIsLoading(true);
    const toastId = toast.loading('Loading...');
    const pedidoId = searchParams.get('pedidoId');

    if(!pedidoId){
      setHasError({...hasError, state: true, message: "No se proporciono ID del pedido."});
      toast.dismiss(toastId);
      return;
    }

    GetCreditos()
    .then(data=>{
      setCreditos(data);
    })
    .catch(error=>{
      console.log(error);
    })

    GetPedido(pedidoId)
    .then(data=>{
      setPedido(data);
      getSolicitudes(data.solicitudes, toastId);
    })
    .catch(error=>{
      toast.dismiss(toastId);
      setHasError({...hasError, state: true, message: error.message});
    })

  },[]);

  const getSolicitudes = async (pedido, toastId) => {
    const entries = Object.entries(JSON.parse(pedido));
    let solicitudesId = [];
    let dataSolicitudes = [];
    entries.forEach(item=>{
      solicitudesId.push(item[0]);
    })
    await Promise.all(solicitudesId.map(async id=>{
      try{
        const data = await GetSolicitud(id);
        data.imagenesProducto = JSON.parse(data.imagenesProducto);
        dataSolicitudes.push(data);
      }
      catch(ex){
        toast(`Ha ocurrido un error al obtener la solicitud ${id}!`);
      }
    }))

    setSolicitudes(dataSolicitudes);
    toast.dismiss(toastId);
    setIsLoading(false);
  }

  const handleClick = () => {
    RealizarPago(pedido.id, useCredits)
    .then(data=>{
      window.location.href = data;
    })
    .catch(error=>{
      switch(error.statusCode){
        case 408:
          toast.error(error.message);
          break;
        case 400:
          toast.error(error.message);
          break;
      }
    });
  }

  return(
    <>
    {
      (!isLoading)&&
      <>
        <CardHeader title="Detalle de la orden"/>
        <Divider />
        <CardContent>
          {
            solicitudes.map((solicitud)=>(
              <Box key={solicitud.id} mt={2}>
                <Grid container spacing={2} mb={3}>
                  <Grid item lg={2}>
                    <img src={solicitud.imagenesProducto[0]} width="100%" style={{borderRadius: "4px"}} />
                  </Grid>
                  <Grid item lg={8}>
                    <Box>
                      <Typography variant="h5">{solicitud.oferta.descripcion}</Typography>
                      <Typography>{`x${solicitud.cantidad}`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2} display="flex" flexDirection="column" justifyContent="center" width="100%">
                    <Typography variant="h4" sx={{height: 'fit-content'}}>{`$${solicitud.oferta.precio}`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            ))
          }
          <Divider />
          <Box display="flex" justifyContent="space-between" my={3}>
            <Typography variant="h5">Total:</Typography>
            <Typography variant="h5">{`$${pedido.amount}`}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" my={3}>
            <Typography variant="h5">Saldo a favor:</Typography>
            <Typography variant="h5">{`$${creditos}`}</Typography>
          </Box>
          <Divider />
          <Box my={3}>
            <FormGroup>
              <FormControlLabel control={<Switch />} label="Usar saldo a favor?" labelPlacement="start" checked={useCredits} onChange={()=>setUseCredits(!useCredits)}/>
              <Button sx={{bgcolor: '#40aa54', ":hover": {bgcolor: '#4bd966'}}} variant="contained" onClick={handleClick}>Pagar</Button>
            </FormGroup>
          </Box>
        </CardContent>
      </>
    }
    </>
  );
}

export default CheckoutComponent;