import React from "react";
import { Container } from "@mui/material";

const StripeAccount = (props) => {


  return(
    <Container>
      <h1>Gracias por crear tu cuenta de Stripe!</h1>
    </Container>
  );
}

export default StripeAccount;