import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { GetPedido } from "http/Checkout";

const Success = (props) => {
  
  const [pedido, setPedido] = useState({});
  const [amount, setAmount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    const toastId = toast.loading('Loading...');
    const pedidoId = searchParams.get('id');

    if(!pedidoId){
      toast.dismiss(toastId);
      return;
    }

    GetPedido(pedidoId)
    .then(data=>{
      setPedido(data);
      setAmount(data.amount ?? 0);
      toast.dismiss(toastId);
    })
    .catch(error=>{
      toast.dismiss(toastId);
    })

  },[]);


  return(
    <Box mt={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <CheckCircle sx={{color: "rgb(34 197 94)", display: "block", width: "80px", height: "80px"}}/>
      <Typography variant="h4" color="white">Pago exitoso</Typography>
      <Typography variant="h6" color="white">Su pago ha sido procesado exitosamente.</Typography>
      <Card sx={{mt: 3, width: "330px"}}>
        <CardHeader component="h4" title="Detalles del pago" />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Cantidad pagada:</Typography>
            <Typography variant="h6">{`$${amount}`}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Success;