import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosApp = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'User-Device': 'AppBastos-Web'
  },
});

axiosApp.interceptors.request.use(function (config) {
  var session = JSON.parse(window.localStorage.getItem('Session'));
  if(session != null){
    config.headers.Authorization = 'Bearer ' + session.firebaseToken;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosApp.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try{
      if(error.response.status == 401 || error.response.status == 403){
        window.localStorage.removeItem("Session");
        window.location.href = "/login";
      }
      else{
        return {
          statusCode: error.response.data.statusCode,
          message: error.response.data.message,
          success: false,
          data: error
        }
      }
    }
    catch {
      window.localStorage.removeItem("Session");
      window.location.href = "/login";
    }
  },
);

export default axiosApp;