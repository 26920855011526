import { createContext } from "react";

export const AuthContext = createContext({
  user: {
    id: "",
    rol: "",
    nombre: "",
    segundoNombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    imagenPerfil: "",
    correo: "",
    lada: "",
    telefono: "",
    ciudad: "",
    sexo: "",
    estatus: ""
  }
});