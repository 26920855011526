import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { GetCreditos } from "http/Checkout";

const Profile = () => {
  var { user } = useContext(AuthContext);

  const [creditos, setCreditos] = useState(0);

  useEffect(()=>{
    GetCreditos()
    .then(data=>{
      setCreditos(data);
    })
    .catch(error=>{
      console.log(error);
    })
  },[])

  return (
    <Box mt={10}>
      <Grid container spacing={4}>
        <Grid item lg={5} width="100%">
          <Card>
            <CardHeader titleTypographyProps={{variant:'h4' }} title="Información personal" />
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography color="gray">Correo electrónico:</Typography>
                <Typography>{user.correo}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography color="gray">Teléfono:</Typography>
                <Typography>{user.telefono}</Typography>
              </Box>
              {/* <Grid container spacing={3}>
                <Grid item lg={12}>
                  <TextField
                    fullWidth
                    label="Correo electrónico"
                    variant="outlined"
                    id="correo"
                    aria-describedby="correo-helper"
                    defaultValue={user.correo}/>
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    fullWidth
                    label="Teléfono"
                    variant="outlined"
                    id="telefono"
                    aria-describedby="correo-helper"
                    defaultValue={user.telefono}/>
                </Grid>
              </Grid> */}
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={7} width="100%">
          <Card>
            <CardHeader titleTypographyProps={{variant:'h4' }} title="Saldo a favor" />
            <CardContent>
              <Typography variant="h3">{`$${creditos}`}</Typography>
              <Typography>Saldo disponible</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Profile;