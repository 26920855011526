import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { AuthContextProvider } from './context/AuthContextProvider';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './views/Header';
import Login from './views/Login';
import Profile from './views/Profile';
import Checkout from './views/Checkout';
import StripeAccount from 'views/StripeAccount';
import Success from 'views/Success';
import Error from 'views/Error';

const ReactRoutes = (props) => {
  const [path, setPath] = useState('');
  const location = useLocation();

  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#212121',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  useEffect(()=>{
    setPath(window.location.pathname);
  },[location])

  return(
    <ThemeProvider theme={theme}>
      <Toaster position="top-center" />
      <AuthContextProvider>
        <Container>
          {(!path.includes('login')) && <Header />}
          <Routes>
            <Route path='/' element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/stripe_account" element={<StripeAccount />} />
            <Route path="/success" element={<Success />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Profile />} />
          </Routes>
        </Container>
      </AuthContextProvider>
    </ThemeProvider>
  )
}

export default ReactRoutes;