import axiosApp from "./Http";

const GetCreditos = async () => {
  const response = await axiosApp.get("/api/Creditos/GetCreditos");
  return new Promise(function(resolve, reject){
    if(response.data.statusCode == 200){
      resolve(response.data.data);
    }else{
      reject(response);
    }
  })
}

const GetPedido = async (id) => {
  const response = await axiosApp.get("/api/Pedidos/GetPedido?pedidoId=" + id);
  return new Promise(function(resolve, reject){
    if(response.data.statusCode == 200){
      resolve(response.data.data);
    }else{
      reject(response);
    }
  })
}

const GetSolicitud = async (id) => {
  const response = await axiosApp.get("/api/Solicitudes/GetSolicitud?solicitudId=" + id);
  return new Promise(function(resolve, reject){
    if(response.data.statusCode == 200){
      resolve(response.data.data);
    }else{
      reject(response);
    }
  })
}

const RealizarPago = async (id, useCredits) => {
  const response = await axiosApp.get(`/api/Pagos/RealizarPago?pedidoId=${id}&useCredits=${useCredits}`);
  return new Promise(function(resolve, reject){
    if(response.data.statusCode == 200){
      resolve(response.data.data);
    }else{
      reject(response);
    }
  })
}

export {
  GetCreditos,
  GetPedido,
  GetSolicitud,
  RealizarPago
}